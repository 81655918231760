import React from "react"
import TermOfUse from "../components/term-of-use"

import Layout from "../components/layout/t1/layout"

const TermOfUsePage = () => {
  return (
    <Layout>
      <TermOfUse />
    </Layout>
  )
}

export default TermOfUsePage
