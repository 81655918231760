import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
const TermofUseContent = () => {
  let Terms = {}
  let info = {}
  const data = useStaticQuery(graphql`
    {
      mdx(fileAbsolutePath: { regex: "/(term-of-use)/" }) {
        exports {
          Terms {
            description
            title
          }
        }
        frontmatter {
          lastUpdate
          titleEula
          paraEula
        }
      }
    }
  `)
  Terms = data.mdx.exports.Terms
  info = data.mdx.frontmatter
  return (
    <div id="Agreement" className="Agreement">
      <div className=" sect Title">
        <h1>{info?.titleEula}</h1>
        <p className="lastUpdate">{info?.lastUpdate}</p>
      </div>
      <div className="description">
        <p
          className="par"
          dangerouslySetInnerHTML={{ __html: info?.paraEula }}
        ></p>
      </div>
      {Terms?.map((elt, index) => {
        return (
          <div className=" sect" key={index}>
            <h2 className="termsTitle">{elt.title}</h2>
            <p
              className="par"
              dangerouslySetInnerHTML={{ __html: elt.description }}
            ></p>
          </div>
        )
      })}
    </div>
  )
}

export default TermofUseContent
