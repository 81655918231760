import React from "react"
import Seo from "../seo"
import TermOfUseContent from "./term-of-use-content"
import { Helmet } from "react-helmet"

const TermOfUse = () => {
  return (
    <div className="container">
      <Seo
        title="Terms of use"
        description="MAXapps’Consumer Terms of Use govern the Use of MAXapps software and services to consumers for their own use"
      />
      <Helmet>
        <meta property="og:title" content="MAXapps: Terms of Use" />
        <meta
          property="og:description"
          content="MAXapps’Consumer Terms of Use govern the Use of MAXapps software and services to consumers for their own use."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/terms-of-use.jpg"
        />
        <meta
          property="og:url"
          content="https://maxapps-maximo.com/terms-of-use"
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXapps: Terms of Use" />
        <meta
          name="twitter:description"
          content="MAXapps’Consumer Terms of Use govern the Use of MAXapps software and services to consumers for their own use."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/terms-of-use.jpg"
        />
      </Helmet>
      <TermOfUseContent />
    </div>
  )
}

export default TermOfUse
